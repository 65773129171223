@import "/node_modules/btn-kit/dist/index.css";
@import "base";
@import "cssbin";

#plane-container {
  pointer-events: none;
  position: fixed;
  inset: 0;
  height: 100vh;
  z-index: 1;

  canvas {

  }
}

.plane {
  width: 100%;
  aspect-ratio: 6 / 5;

  @include min-width(touch) {
    img {
      display: none;
    }
  }
}

.aside-item {
  will-change: transform;
}




//#plane-container {border: 5px solid red;
//  opacity: 0;
//
//  body.is-page-projects & {
//    transition: opacity 0.75s 14.5s ease-in;
//    opacity: 1;
//  }
//}
