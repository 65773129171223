:root {
  --header-height: 100px;
  --aside-width: 22vw;
  --glob-gap: 20px;

  @media (width >= 1200px) {
    --header-height: 120px;
  }

  // offsets
  --vertical-offset: 50px;
  --horizontal-offset: 15px;

  @media (width >= 992px) {
    --horizontal-offset: 2.8vw;
  }

  @media (width >= 1200px) {
    --vertical-offset: 120px;
  }

  // z-orders
  --z-index-priority: 1000;
  --z-index-preloader-circle: 180;
  --z-index-preloader: 180;
  --z-index-hamburger: 170;
  --z-index-drawer: 160;
  --z-index-app-transition: 150;
  --z-index-cursor-blob: 140;
  --z-index-header-logotype: 130;
  --z-index-indicator: 130;
  --z-index-black-frame: 100;

  // fonts
  --min-fs: .85;
  --max-fs: 1.3;
  --min-vw: 100;
  --max-vw: 150;
  --font-family-default: "Noto Sans Display", "Open Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --default-line-height: 1.5;
  --heading-line-height: 1.3em;

  // base colors
  --c-silver-white: rgb(230 232 235);
  --c-smoky-white: rgb(254 254 254);
  --c-snow-white: rgb(251 251 252);
  --c-black-amber: rgb(8 8 8);
  --c-wet-asphalt: rgb(76 76 76);
  --c-dull-grey: rgb(92 92 92);
  --c-indian-red: rgb(193 62 62);
  --c-cinnabar: rgb(246 86 65);
  --c-gray: rgb(128 128 128);
  --c-abdel-kerim-beard: rgb(214 214 214);

  // background
  --c-background-primary: var(--c-smoky-white);
  --c-background-secondary: var(--c-black-amber);

  // text
  --c-text-primary: var(--c-black-amber);
  --c-text-secondary: var(--c-dull-grey);
  --c-text-tertiary: var(--c-smoky-white);

  // borders
  --border-shadow-color: var(--c-black-amber);

  // meta
  --meta-theme-color: var(--c-blue-royal);

  // scrollbar
  --c-scrollbar-track: var(--c-background-secondary);
  --c-scrollbar-thumb: var(--c-smoky-white);
  --c-scrollbar-hover: var(--c-smoky-white);
}
