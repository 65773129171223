.btn-group {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.btn-group:not(.btn-group--merge) .btn:not(:last-child) {
  margin-right: var(--bk-group-padding, 20px);
}

.btn-group--left {
  justify-content: flex-start;
}

.btn-group--center {
  justify-content: center;
}

.btn-group--right {
  justify-content: flex-end;
}

.btn-group--fill {
  justify-content: space-between;
}

.btn-group--fill .btn {
  flex: 1;
}

.btn-group--merge .btn {
  margin-right: 0;
}

.btn-group--merge .btn:first-child {
  border-radius: 4px 0 0 4px;
}

.btn-group--merge .btn:last-child {
  border-radius: 0 4px 4px 0;
}

.btn-group--merge .btn:not(:last-child) {
  margin-right: -1px;
}

.btn {
  --bk-background: hsla(var(--bk-background-h, 0) var(--bk-background-s, 0%) var(--bk-background-l, 68%) / var(--bk-background-a, 1));
  --bk-color: hsla(var(--bk-color-h, 0) var(--bk-color-s, 0%) var(--bk-color-l, 0%) / var(--bk-color-a, 1));
  font-family: inherit;
  font-size: var(--bk-font-size, 16px);
  background-color: var(--bk-background);
  color: var(--bk-color);
  height: var(--bk-button-size, 52px);
  box-sizing: border-box;
  text-align: center;
  text-transform: var(--bk-text-transform, uppercase);
  white-space: nowrap;
  cursor: pointer;
  touch-action: manipulation;
  -webkit-user-select: none;
  user-select: none;
  line-height: 1;
  font-weight: var(--bk-font-weight, 500);
  letter-spacing: var(--bk-letter-spacing, .025em);
  -webkit-tap-highlight-color: transparent;
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  text-decoration: none;
  transition-property: background-color, color, opacity, box-shadow;
  transition-duration: .225s;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
}

@media (hover: hover) and (pointer: fine) {
  .btn:hover {
    --bk-background-hover-s: calc(var(--bk-background-s, 0%)  - 5%);
    --bk-background-hover-l: calc(var(--bk-background-l, 68%)  - 10%);
  }

  .btn:hover:not(.btn--outlined):not(.btn--text) {
    background-color: hsla(var(--bk-background-h, 0) var(--bk-background-hover-s, 0%) var(--bk-background-hover-l, 68%) / var(--bk-background-a, 1));
  }

  .btn:hover:not(.btn--contained) {
    background-color: hsla(var(--bk-background-h, 0) var(--bk-background-hover-s, 0%) var(--bk-background-hover-l, 68%) / .08);
  }

  .btn:active {
    --bk-background-active-s: calc(var(--bk-background-s, 0%)  - 7.5%);
    --bk-background-active-l: calc(var(--bk-background-l, 68%)  - 25%);
  }

  .btn:active:not(.btn--ripple):not(.btn--outlined):not(.btn--text) {
    background-color: hsla(var(--bk-background-h, 0) var(--bk-background-active-s, 0%) var(--bk-background-active-l, 68%) / var(--bk-background-a, 1));
  }

  .btn:active:not(.btn--ripple) {
    background-color: hsla(var(--bk-background-h, 0) var(--bk-background-active-s, 0%) var(--bk-background-active-l, 68%) / .28);
  }
}

.btn .label {
  z-index: 1;
  margin: 0 calc(var(--bk-button-size, 52px) / 2);
  position: relative;
}

.btn .label + [class*="icon"]:last-child {
  margin-right: calc(var(--bk-button-size, 52px) / 2);
  margin-left: calc(var(--bk-button-size, 52px) / 3 * -1);
  transform: translate(calc(var(--bk-button-size) / 8));
}

.btn [class*="icon"] {
  fill: var(--bk-color);
  width: var(--bk-icon-size, 24px);
  height: var(--bk-icon-size, 24px);
  transition-property: fill, stroke;
  transition-duration: .225s;
}

.btn [class*="icon"]:first-child {
  margin: 0 calc(var(--bk-button-size, 52px) / 2 + var(--bk-icon-size, 24px) * -1 / 2);
}

.btn [class*="icon"]:not(:last-child) {
  margin-right: calc(var(--bk-button-size, 52px) / 3 * -1);
  margin-left: calc(var(--bk-button-size, 52px) / 2);
  transform: translate(calc(var(--bk-button-size) / 8 * -1));
}

.btn--rounded {
  border-radius: var(--bk-rounded-radius, 4px);
}

.btn--round {
  border-radius: var(--bk-round-radius, 100px);
}

.btn--outlined {
  --bk-color: var(--bk-background);
  box-shadow: inset 0 0 0 var(--bk-border-width, 1px) var(--bk-outline-color, var(--bk-background));
  background-color: #0000;
}

.btn--text {
  background-color: #0000;
}

.btn--text:not(.btn--progress):not(.btn--disabled) {
  --bk-color: var(--bk-background);
}

.btn--ripple:before {
  content: "";
  pointer-events: none;
  top: calc(var(--y) * 1px);
  left: calc(var(--x) * 1px);
  width: calc(var(--d) * 1px);
  height: calc(var(--d) * 1px);
  opacity: calc(var(--o, 1) * var(--bk-ripple-opacity, .45));
  transition: calc(var(--t, 0) * var(--bk-ripple-duration, .75s)) var(--bk-ripple-easing, cubic-bezier(.61, 1, .88, 1));
  transform: translate(-50%, -50%) scale(var(--s, 1));
  transform-origin: center;
  background-color: var(--bk-ripple-color, hsla(var(--bk-background-h) 20% 65% / var(--bk-background-a, 1)));
  border-radius: 50%;
  display: block;
  position: absolute;
}

.btn--progress {
  --bk-color: transparent;
  cursor: default;
  pointer-events: none;
}

.btn--progress:after {
  content: "";
  box-sizing: border-box;
  -webkit-mask-composite: xor;
  background-image: conic-gradient(from 45deg, transparent, var(--bk-ripple-color, hsla(var(--bk-background-h) 50% 75% / var(--bk-background-a, 1))));
  -webkit-mask-composite: xor;
  -webkit-mask-source-type: auto, auto;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  margin: auto;
  padding: 5px;
  animation: .825s linear infinite Spin;
  position: absolute;
  inset: 0;
  -webkit-mask-image: linear-gradient(#bfbfbf 0 0), linear-gradient(#bfbfbf 0 0);
  mask-image: linear-gradient(#bfbfbf 0 0), linear-gradient(#bfbfbf 0 0);
  -webkit-mask-position: 0 0, 0 0;
  mask-position: 0 0, 0 0;
  -webkit-mask-size: auto, auto;
  mask-size: auto, auto;
  -webkit-mask-repeat: repeat, repeat;
  mask-repeat: repeat, repeat;
  -webkit-mask-clip: content-box, border-box;
  mask-clip: content-box, border-box;
  -webkit-mask-origin: content-box, border-box;
  mask-origin: content-box, border-box;
  mask-composite: exclude;
  mask-mode: match-source, match-source;
}

@keyframes Spin {
  to {
    transform: rotate(1turn);
  }
}

.btn--disabled, .btn[disabled] {
  --bk-color-s: 0%;
  --bk-color-l: 60%;
  --bk-background-s: 0%;
  --bk-background-l: 75%;
  cursor: default;
  pointer-events: none;
}

:root {
  --max-fs: 1;
  --min-fs: .75;
  --max-vw: 120;
  --min-vw: 80;
  --min-fs-rem: calc(var(--min-fs) * 1rem);
  --max-fs-rem: calc(var(--max-fs) * 1rem);
  --min-vw-rem: calc(var(--min-vw) * 1rem);
  --slope: calc((var(--max-fs)  - var(--min-fs)) * (100vw - var(--min-vw-rem)) / (var(--max-vw)  - var(--min-vw)));
  --font-size-clamp: clamp(var(--min-fs-rem), calc(var(--min-fs-rem)  + var(--slope)), var(--max-fs-rem));
  --default-line-height: 1.5;
  --heading-line-height: 1.3em;
}

html {
  font-feature-settings: "kern" 1, "liga" 0;
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;
  box-sizing: border-box;
  font-size: 10px;
}

body {
  font-family: var(--font-family-default, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji");
  font-size: var(--font-size-clamp);
  line-height: var(--default-line-height);
  font-weight: 400;
}

*, :before, :after {
  box-sizing: inherit;
}

a {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

.display-large {
  font-size: 5.7em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.08em;
}

.display-medium {
  font-size: 4.5em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.display-small {
  font-size: 3.6em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.07em;
}

.headline-large {
  font-size: 3.2em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-medium {
  font-size: 2.8em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.06em;
}

.headline-small {
  font-size: 2.4em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.05em;
}

.title-large {
  font-size: 2.2em;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.04em;
}

.title-medium {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  margin-left: -.03em;
  font-weight: 600;
}

.title-small {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: var(--heading-line-height);
  margin-top: calc(var(--heading-line-height) / 2 * 2.5);
  margin-bottom: calc(var(--heading-line-height) / 2 * 1);
  text-wrap: balance;
  max-inline-size: 50ch;
  font-weight: 600;
}

.label-large {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
}

.label-medium {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.label-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.6rem;
}

.body-large {
  text-wrap: balance;
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

.body-medium {
  text-wrap: balance;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 2rem;
}

.body-small {
  text-wrap: balance;
  margin-bottom: .8rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

ul, ol {
  padding-left: 0;
  font-size: 1.4rem;
  line-height: 2rem;
  list-style-position: outside;
}

@font-face {
  font-family: Noto Sans Display;
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url("noto-sans-display-cyrillic-ext.9a8b3878.woff2") format("woff2");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Noto Sans Display;
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url("noto-sans-display-cyrillic.7aa86a3b.woff2") format("woff2");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Noto Sans Display;
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url("noto-sans-display-greek-ext.3d718788.woff2") format("woff2");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Noto Sans Display;
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url("noto-sans-display-greek.da4ad110.woff2") format("woff2");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Noto Sans Display;
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url("noto-sans-display-latin-ext.fbc1c15d.woff2") format("woff2");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Noto Sans Display;
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url("noto-sans-display-latin.af9100c1.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Noto Sans Display;
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url("noto-sans-display-cyrillic-ext.9a8b3878.woff2") format("woff2");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Noto Sans Display;
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url("noto-sans-display-cyrillic.7aa86a3b.woff2") format("woff2");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Noto Sans Display;
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url("noto-sans-display-greek-ext.3d718788.woff2") format("woff2");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Noto Sans Display;
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url("noto-sans-display-greek.da4ad110.woff2") format("woff2");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Noto Sans Display;
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url("noto-sans-display-latin-ext.fbc1c15d.woff2") format("woff2");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Noto Sans Display;
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url("noto-sans-display-latin.af9100c1.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Noto Sans Display;
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url("noto-sans-display-cyrillic-ext.9a8b3878.woff2") format("woff2");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Noto Sans Display;
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url("noto-sans-display-cyrillic.7aa86a3b.woff2") format("woff2");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Noto Sans Display;
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url("noto-sans-display-greek-ext.3d718788.woff2") format("woff2");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Noto Sans Display;
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url("noto-sans-display-greek.da4ad110.woff2") format("woff2");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Noto Sans Display;
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url("noto-sans-display-latin-ext.fbc1c15d.woff2") format("woff2");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Noto Sans Display;
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url("noto-sans-display-latin.af9100c1.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Noto Serif;
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url("noto-serif-cyrillic-ext.fd944c84.woff2") format("woff2");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

pre, code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

:root {
  --header-height: 100px;
  --aside-width: 22vw;
  --glob-gap: 20px;
  --vertical-offset: 50px;
  --horizontal-offset: 15px;
  --z-index-priority: 1000;
  --z-index-preloader-circle: 180;
  --z-index-preloader: 180;
  --z-index-hamburger: 170;
  --z-index-drawer: 160;
  --z-index-app-transition: 150;
  --z-index-cursor-blob: 140;
  --z-index-header-logotype: 130;
  --z-index-indicator: 130;
  --z-index-black-frame: 100;
  --min-fs: .85;
  --max-fs: 1.3;
  --min-vw: 100;
  --max-vw: 150;
  --font-family-default: "Noto Sans Display", "Open Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --default-line-height: 1.5;
  --heading-line-height: 1.3em;
  --c-silver-white: #e6e8eb;
  --c-smoky-white: #fefefe;
  --c-snow-white: #fbfbfc;
  --c-black-amber: #080808;
  --c-wet-asphalt: #4c4c4c;
  --c-dull-grey: #5c5c5c;
  --c-indian-red: #c13e3e;
  --c-cinnabar: #f65641;
  --c-gray: gray;
  --c-abdel-kerim-beard: #d6d6d6;
  --c-background-primary: var(--c-smoky-white);
  --c-background-secondary: var(--c-black-amber);
  --c-text-primary: var(--c-black-amber);
  --c-text-secondary: var(--c-dull-grey);
  --c-text-tertiary: var(--c-smoky-white);
  --border-shadow-color: var(--c-black-amber);
  --meta-theme-color: var(--c-blue-royal);
  --c-scrollbar-track: var(--c-background-secondary);
  --c-scrollbar-thumb: var(--c-smoky-white);
  --c-scrollbar-hover: var(--c-smoky-white);
}

@media (width >= 1200px) {
  :root {
    --header-height: 120px;
  }
}

@media (width >= 992px) {
  :root {
    --horizontal-offset: 2.8vw;
  }
}

@media (width >= 1200px) {
  :root {
    --vertical-offset: 120px;
  }
}

body::-webkit-scrollbar {
  opacity: 0;
  width: 20px;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--c-scrollbar-thumb, var(--c-black-amber));
  border: 8px solid var(--c-scrollbar-track, var(--c-black-amber));
  border-radius: 2px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: var(--c-scrollbar-hover, var(--c-black-amber));
}

body::-webkit-scrollbar-track {
  background-color: var(--c-scrollbar-track, var(--c-black-amber));
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.app-preloader {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  z-index: var(--z-index-preloader-circle);
  background-color: var(--c-black-amber);
  place-content: center;
  display: grid;
  position: fixed;
  inset: 0;
}

.app-preloader__chars {
  z-index: 1;
  justify-content: center;
  align-items: center;
  margin: auto;
  transition: opacity .75s ease-out;
  display: flex;
  position: fixed;
  inset: 0;
}

body.char-done .app-preloader__chars {
  opacity: 0;
}

.app-preloader__chars .char {
  color: var(--c-indian-red);
  animation: chars 1.2s calc(var(--char) * 50ms) infinite ease-in-out normal;
  font-size: 4em;
  font-weight: 900;
}

@keyframes chars {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05, 1.5);
  }

  100% {
    transform: scale(1);
  }
}

.app-preloader__circle {
  --circle: 140vmax;
  will-change: opacity;
  width: var(--circle);
  height: var(--circle);
  border-radius: var(--circle);
  background-color: var(--c-background-primary);
  transform: scale(0);
}

body.circle-done .app-preloader__circle {
  animation: 1.2s forwards circle;
}

@keyframes circle {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.btn {
  position: relative;
}

.btn--sm, .btn--md {
  --bk-button-size: 38px;
}

@media (width >= 1200px) {
  .btn--md {
    --bk-font-size: 17px;
    --bk-button-size: 52px;
  }
}

.btn--lg {
  --bk-button-size: 60px;
}

@media (width >= 992px) {
  .btn--lg {
    --bk-button-size: 70px;
  }
}

.btn--theme-big-white {
  --bk-color: var(--c-text-secondary);
  --bk-background: var(--c-background-tertiary);
  --bk-border-width: 2px;
  --bk-rounded-radius: 5px;
  --bk-text-transform: none;
  --bk-font-weight: 400;
  --bk-font-size: 16px;
  --bk-letter-spacing: .025em;
}

@media (hover: hover) and (pointer: fine) {
  .btn--theme-big-white:before {
    content: "";
    z-index: -1;
    background-color: var(--c-background-tertiary);
    transform-origin: bottom;
    border-radius: 100% 100% 0 0;
    transition: transform .4s cubic-bezier(.5, 0, 0, 1) 40ms, border-radius .5s cubic-bezier(.5, 0, 0, 1);
    position: absolute;
    inset: 0;
    transform: scaleY(0);
  }

  .btn--theme-big-white:hover {
    --bk-color: var(--c-text-primary);
  }

  .btn--theme-big-white:hover .label, .btn--theme-big-white:hover .svg-icon {
    opacity: .9;
  }

  .btn--theme-big-white:hover:before {
    border-radius: 2px;
    transition: transform .4s cubic-bezier(.5, 0, 0, 1), border-radius .5s cubic-bezier(.5, 0, 0, 1) 30ms;
    transform: scaleY(1);
  }
}

.btn--theme-hamburger {
  --bk-color: white;
  --bk-background: var(--c-indian-red);
  --bk-font-weight: 500;
  --bk-font-size: 14px;
  --bk-letter-spacing: .025em;
  border: 2px solid var(--c-background-primary);
  width: 92px;
}

.btn--theme-common {
  --bk-color: var(--c-text-primary);
  --bk-background: #333c;
  --bk-border-width: 1px;
  --bk-font-weight: 400;
  --bk-font-size: 15px;
  --bk-letter-spacing: .025em;
}

@media (width >= 1200px) {
  .btn--theme-common {
    --bk-font-size: 17px;
  }
}

.btn--sm .svg-icon {
  fill: var(--c-black-amber);
}

.link {
  text-decoration: none;
  transition: color .225s;
}

.link--common {
  text-transform: uppercase;
  color: var(--c-text-primary);
  position: relative;
}

@media (hover: hover) and (pointer: fine) {
  .link--common:before {
    pointer-events: none;
    content: "";
    z-index: -1;
    background-color: var(--c-dull-grey);
    transform-origin: 100% 0;
    width: 100%;
    height: 2px;
    transition: transform .5s cubic-bezier(.2, 1, .3, 1);
    position: absolute;
    inset: auto 0 1px;
    transform: scale3d(0, 1, 1);
  }

  .link--common.link-throttled:before, .link--common:hover:before {
    transform-origin: 0;
    transform: scale3d(1, 1, 1);
  }
}

.link--common.link-active {
  color: var(--c-dull-grey);
  text-decoration: line-through;
}

.link-active {
  cursor: default;
  pointer-events: none;
  position: relative;
}

.link.link-throttled {
  pointer-events: none;
  cursor: default;
}

.link--red {
  color: var(--c-indian-red);
  text-decoration: none;
  position: relative;
}

@media (hover: hover) and (pointer: fine) {
  .link--red:before {
    pointer-events: none;
    content: "";
    z-index: -1;
    background-color: var(--c-indian-red);
    transform-origin: 100% 0;
    width: 100%;
    height: 2px;
    transition: transform .5s cubic-bezier(.2, 1, .3, 1);
    position: absolute;
    inset: auto 0 1px;
    transform: scale3d(0, 1, 1);
  }

  .link--red:hover:before {
    transform-origin: 0;
    transform: scale3d(1, 1, 1);
  }
}

.display-medium {
  text-transform: uppercase;
  font-weight: 500;
}

.body-medium {
  color: var(--c-text-secondary);
  margin-bottom: 5px;
  font-size: 13px;
}

.headline-large {
  margin-top: var(--vertical-offset);
  text-transform: none;
  font-weight: 400;
  line-height: 1.4;
}

.title-large {
  text-transform: uppercase;
  font-size: 1.4em;
  line-height: 1.7;
}

.display-large {
  opacity: 0;
  margin-top: var(--vertical-offset);
  margin-bottom: calc(var(--vertical-offset) / 3);
  text-transform: none;
  margin-left: -.04em;
  font-size: max(7.6em, 7.6vw);
  font-weight: 400;
  transition: opacity .75s ease-in;
}

body.page-loaded .display-large {
  opacity: 1;
}

body {
  background-color: var(--c-black-amber);
  overflow-x: clip;
}

.page {
  --stripes-c-thread: transparent;
  --stripes-c-wide: var(--c-snow-white);
  --stripes-width: calc((100% + var(--glob-gap))  - var(--aside-width));
  grid-template: minmax(var(--header-height), auto) 1fr auto / 1fr;
  background-color: var(--c-background-primary);
  gap: 0;
  width: 100vw;
  min-height: 100vh;
  display: grid;
  position: relative;
}

body[class^="is-page-bin-"] .page, body.is-page-projects .page {
  --stripes-width: calc(100% + var(--glob-gap));
}

@media (width >= 1200px) and (any-pointer: fine) {
  .page {
    grid-template: minmax(var(--header-height), auto) 1fr auto / 1fr 1fr var(--aside-width);
  }

  .page:after {
    opacity: 0;
    pointer-events: none;
    content: "";
    box-shadow: 5px 5px 0 0 var(--c-black-amber) inset, 5px -5px 0px 0px var(--c-black-amber) inset;
    z-index: var(--z-index-black-frame);
    transition: opacity .75s ease-in;
    position: fixed;
    inset: 0;
  }

  body.page-loaded .page:after {
    opacity: 1;
  }

  body[class^="is-page-bin-"] .page, body.is-page-projects .page {
    grid-template: minmax(var(--header-height), auto) 1fr auto / 1fr 1fr;
  }
}

.header {
  z-index: 2;
  position: relative;
}

@media (width >= 1200px) and (any-pointer: fine) {
  .header {
    grid-area: 1 / 1 / 2 / 3;
  }

  .aside {
    grid-area: 1 / 3 / 4 / 4;
  }
}

.main {
  z-index: 2;
  position: relative;
  overflow: hidden;
}

@media (width >= 1200px) and (any-pointer: fine) {
  .main {
    grid-area: 2 / 1 / 3 / 3;
  }
}

.footer {
  z-index: 2;
  position: relative;
}

@media (width >= 1200px) and (any-pointer: fine) {
  .footer {
    grid-area: 3 / 1 / 4 / 3;
  }
}

.common__body {
  flex-wrap: wrap;
  gap: 1px 0;
  max-width: 230px;
  margin-top: 2px;
  display: flex;
}

.drawer-header .common__body {
  flex-direction: column;
  max-width: none;
}

@media (width <= 991.98px) {
  .header .common__body {
    flex-direction: column;
    max-width: none;
  }
}

.common__body .displace, .common__body .link {
  text-transform: uppercase;
  color: var(--c-text-primary);
  align-items: center;
  font-size: 14px;
  display: flex;
}

@media (width >= 992px) {
  .common__body .displace, .common__body .link {
    width: fit-content;
    font-size: 16px;
    display: inline-flex;
  }
}

@media (width <= 991.98px) {
  .common__body .displace, .common__body .link {
    height: 32px;
    line-height: 1;
  }
}

.common__body .displace:not(:last-child):after {
  content: " ";
}

.common__body .link:not(:last-child) {
  margin-right: 8px;
}

.common__body .link:not(:last-child):after {
  content: ",";
}

.common--name .link-active {
  color: inherit;
  text-decoration: none;
}

.separator-wave {
  opacity: 0;
  margin-top: var(--vertical-offset);
  --mask: radial-gradient(14.64px at 50% calc(100% + 10px), #0000 calc(99% - 1px), #000 calc(101% - 1px) 99%, #0000 101%) calc(50% - 20px) calc(50% - 5.5px + .5px) / 40px 11px repeat-x, radial-gradient(14.64px at 50% -10px, #0000 calc(99% - 1px), #000 calc(101% - 1px) 99%, #0000 101%) 50% calc(50% + 5.5px) / 40px 11px repeat-x;
  -webkit-mask: var(--mask);
  -webkit-mask: var(--mask);
  mask: var(--mask);
  background: var(--c-gray);
  width: 100%;
  height: 15px;
  transition: opacity .75s ease-in;
}

body.page-loaded .separator-wave {
  opacity: 1;
}

video.video {
  object-fit: cover;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  position: relative;
}

#image-flow-canvas {
  display: none;
}

.project #image-flow-canvas {
  display: block;
}

#plane-container {
  pointer-events: none;
  z-index: 1;
  height: 100vh;
  position: fixed;
  inset: 0;
}

.plane {
  aspect-ratio: 6 / 5;
  width: 100%;
}

@media (width >= 1200px) and (any-pointer: fine) {
  .plane img {
    display: none;
  }
}

.aside-item {
  will-change: transform;
}
/*# sourceMappingURL=index.c9802246.css.map */
