.separator-wave {
  @include component-loaded;
  margin-top: var(--vertical-offset);
  width: 100%;
  height: 15px;
  --mask: radial-gradient(14.64px at 50% calc(100% + 10.00px), #0000 calc(99% - 1px), #000 calc(101% - 1px) 99%, #0000 101%) calc(50% - 20px) calc(50% - 5.5px + .5px) / 40px 11px repeat-x,
  radial-gradient(14.64px at 50% -10px, #0000 calc(99% - 1px), #000 calc(101% - 1px) 99%, #0000 101%) 50% calc(50% + 5.5px) / 40px 11px repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  background: var(--c-gray);
}
