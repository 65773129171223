.app-preloader {
  pointer-events: none;
  user-select: none;
  position: fixed;
  inset: 0;
  z-index: var(--z-index-preloader-circle);
  display: grid;
  place-content: center;
  background-color: var(--c-black-amber);

  &__chars {
    position: fixed;
    inset: 0;
    margin: auto;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.75s ease-out;

    body.char-done & {
      opacity: 0;
    }

    .char {
      font-size: 4em;
      font-weight: 900;
      color: var(--c-indian-red);
      animation: chars 1.2s calc(var(--char) * 0.05s) infinite ease-in-out normal;

      @keyframes chars {
        0% {
          transform: scale(1);
        }

        50% {
          transform: scale(1.05, 1.5);
        }

        100% {
          transform: scale(1);
        }
      }
    }
  }

  &__circle {
    --circle: 140vmax;

    will-change: opacity;
    width: var(--circle);
    height: var(--circle);
    border-radius: var(--circle);
    background-color: var(--c-background-primary);
    transform: scale(0);

    body.circle-done & {
      animation: circle 1.2s ease forwards;
    }

    @keyframes circle {
      from {
        transform: scale(0);
      }

      to {
        transform: scale(1);
      }
    }
  }
}
