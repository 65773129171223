/*
  Noto Sans Display
  regular 400
  medium 500
  extra-bold 800
*/

/*
  Noto Serif
  light 300
  regular 400
*/

@font-face {
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/noto-sans-display-cyrillic-ext.woff2") format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/noto-sans-display-cyrillic.woff2") format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/noto-sans-display-greek-ext.woff2") format('woff2');
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/noto-sans-display-greek.woff2") format('woff2');
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/noto-sans-display-latin-ext.woff2") format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/noto-sans-display-latin.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/noto-sans-display-cyrillic-ext.woff2") format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/noto-sans-display-cyrillic.woff2") format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/noto-sans-display-greek-ext.woff2") format('woff2');
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/noto-sans-display-greek.woff2") format('woff2');
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/noto-sans-display-latin-ext.woff2") format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/noto-sans-display-latin.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/noto-sans-display-cyrillic-ext.woff2") format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/noto-sans-display-cyrillic.woff2") format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/noto-sans-display-greek-ext.woff2") format('woff2');
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/noto-sans-display-greek.woff2") format('woff2');
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/noto-sans-display-latin-ext.woff2") format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Noto Sans Display';
  font-style: normal;
  font-weight: 800;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/noto-sans-display-latin.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url("../fonts/noto-serif-cyrillic-ext.woff2") format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

//@font-face {
//  font-family: 'Noto Serif';
//  font-style: normal;
//  font-weight: 300;
//  font-stretch: 100%;
//  font-display: swap;
//  src: url("../fonts/noto-serif-cyrillic.woff2") format('woff2');
//  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//}
//
//@font-face {
//  font-family: 'Noto Serif';
//  font-style: normal;
//  font-weight: 300;
//  font-stretch: 100%;
//  font-display: swap;
//  src: url("../fonts/noto-serif-greek-ext.woff2") format('woff2');
//  unicode-range: U+1F00-1FFF;
//}
//
//@font-face {
//  font-family: 'Noto Serif';
//  font-style: normal;
//  font-weight: 300;
//  font-stretch: 100%;
//  font-display: swap;
//  src: url("../fonts/noto-serif-greek.woff2") format('woff2');
//  unicode-range: U+0370-03FF;
//}
//
//@font-face {
//  font-family: 'Noto Serif';
//  font-style: normal;
//  font-weight: 300;
//  font-stretch: 100%;
//  font-display: swap;
//  src: url("../fonts/noto-serif-latin-ext.woff2") format('woff2');
//  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
//}
//
//@font-face {
//  font-family: 'Noto Serif';
//  font-style: normal;
//  font-weight: 300;
//  font-stretch: 100%;
//  font-display: swap;
//  src: url("../fonts/noto-serif-latin.woff2") format('woff2');
//  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
//}
//
//@font-face {
//  font-family: 'Noto Serif';
//  font-style: normal;
//  font-weight: 400;
//  font-stretch: 100%;
//  font-display: swap;
//  src: url("../fonts/noto-serif-cyrillic-ext.woff2") format('woff2');
//  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
//}
//
//@font-face {
//  font-family: 'Noto Serif';
//  font-style: normal;
//  font-weight: 400;
//  font-stretch: 100%;
//  font-display: swap;
//  src: url("../fonts/noto-serif-cyrillic.woff2") format('woff2');
//  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
//}
//
//@font-face {
//  font-family: 'Noto Serif';
//  font-style: normal;
//  font-weight: 400;
//  font-stretch: 100%;
//  font-display: swap;
//  src: url("../fonts/noto-serif-greek-ext.woff2") format('woff2');
//  unicode-range: U+1F00-1FFF;
//}
//
//@font-face {
//  font-family: 'Noto Serif';
//  font-style: normal;
//  font-weight: 400;
//  font-stretch: 100%;
//  font-display: swap;
//  src: url("../fonts/noto-serif-greek.woff2") format('woff2');
//  unicode-range: U+0370-03FF;
//}
//
//@font-face {
//  font-family: 'Noto Serif';
//  font-style: normal;
//  font-weight: 400;
//  font-stretch: 100%;
//  font-display: swap;
//  src: url("../fonts/noto-serif-latin-ext.woff2") format('woff2');
//  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
//}
//
//@font-face {
//  font-family: 'Noto Serif';
//  font-style: normal;
//  font-weight: 400;
//  font-stretch: 100%;
//  font-display: swap;
//  src: url("../fonts/noto-serif-latin.woff2") format('woff2');
//  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
//}
