@import "../base/mixins";

.btn {
  position: relative;

  &--sm {
    --bk-button-size: 38px;
  }

  &--md {
    --bk-button-size: 38px;

    @include min-width(tablet-landscape) {
      --bk-font-size: 17px;
      --bk-button-size: 52px;
    }
  }

  &--lg {
    --bk-button-size: 60px;

    @include min-width(tablet-portrait) {
      --bk-button-size: 70px;
    }
  }

  &--theme {
    &-big-white {
      --bk-color: var(--c-text-secondary);
      --bk-background: var(--c-background-tertiary);
      --bk-border-width: 2px;
      --bk-rounded-radius: 5px;
      --bk-text-transform: none;
      --bk-font-weight: 400;
      --bk-font-size: 16px;
      --bk-letter-spacing: 0.025em;

      @media (hover: hover) and (pointer: fine) {
        &::before {
          content: '';
          position: absolute;
          inset: 0;
          z-index: -1;
          background-color: var(--c-background-tertiary);
          border-radius: 100% 100% 0 0;
          transform: scaleY(0);
          transform-origin: bottom center;
          transition: transform 400ms cubic-bezier(0.5, 0, 0, 1) 40ms, border-radius 500ms cubic-bezier(0.5, 0, 0, 1);
        }

        &:hover {
          --bk-color: var(--c-text-primary);

          .label,
          .svg-icon {
            opacity: 0.9;
          }

          &::before {
            transition: transform 400ms cubic-bezier(0.5, 0, 0, 1), border-radius 500ms cubic-bezier(0.5, 0, 0, 1) 30ms;
            transform: scaleY(1);
            border-radius: 2px;
          }
        }
      }
    }

    &-hamburger {
      --bk-color: white;
      --bk-background: var(--c-indian-red);
      --bk-font-weight: 500;
      --bk-font-size: 14px;
      --bk-letter-spacing: 0.025em;

      width: 92px;
      border: 2px solid var(--c-background-primary);
    }

    &-common {
      --bk-color: var(--c-text-primary);
      --bk-background: hsla(0deg 0% 20% / 80%);
      --bk-border-width: 1px;
      --bk-font-weight: 400;
      --bk-font-size: 15px;
      --bk-letter-spacing: 0.025em;

      @include min-width(tablet-landscape) {
        --bk-font-size: 17px;
      }
    }
  }

  &--sm {
    .svg-icon {
      fill: var(--c-black-amber);
    }
  }

  &--sm {
    .svg-icon {
      fill: var(--c-black-amber);
    }
  }
}
