.common {
  &__body {
    display: flex;
    flex-wrap: wrap;
    gap: 1px 0;
    margin-top: 2px;
    max-width: 230px;

    .drawer-header & {
      max-width: none;
      flex-direction: column;
    }

    @include max-width(tablet-portrait) {
      .header & {
        max-width: none;
        flex-direction: column;
      }
    }

    .displace,
    .link {
      display: flex;
      align-items: center;
      font-size: 14px;
      text-transform: uppercase;
      color: var(--c-text-primary);

      @include min-width(tablet-portrait) {
        display: inline-flex;
        width: fit-content;
        font-size: 16px;
      }

      @include max-width(tablet-portrait) {
        line-height: 1;
        height: 32px;
      }
    }

    .displace:not(:last-child) {
      &::after {
        content: '\00A0';
      }
    }

    .link:not(:last-child) {
      margin-right: 8px;

      &::after {
        content: ',';
      }
    }
  }

  &--name {
    .link-active {
      text-decoration: none;
      color: inherit;
    }
  }
}
