@import "../base/mixins";

.link {
  text-decoration: none;
  transition: color 225ms ease;

  &--common {
    position: relative;
    text-transform: uppercase;
    color: var(--c-text-primary);

    //color: var(--c-indian-red);

    @media (hover: hover) and (pointer: fine) {
      &::before {
        pointer-events: none;
        content: '';
        position: absolute;
        inset: auto 0 1px;
        z-index: -1;
        width: 100%;
        height: 2px;
        transform: scale3d(0, 1, 1);
        background-color: var(--c-dull-grey);
        transform-origin: 100% 0;
        transition: transform 0.5s;
        transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
      }

      &.link-throttled,
      &:hover {
        &::before {
          transform-origin: 0 50%;
          transform: scale3d(1, 1, 1);
        }
      }
    }

    &.link-active {
      text-decoration: line-through;
      color: var(--c-dull-grey);
    }
  }

  &-active {
    position: relative;
    cursor: default;
    pointer-events: none;
  }

  &.link-throttled {
    pointer-events: none;
    cursor: default;
  }

  &--red {
    position: relative;
    text-decoration: none;
    color: var(--c-indian-red);

    @media (hover: hover) and (pointer: fine) {
      &::before {
        pointer-events: none;
        content: '';
        position: absolute;
        inset: auto 0 1px;
        z-index: -1;
        width: 100%;
        height: 2px;
        transform: scale3d(0, 1, 1);
        background-color: var(--c-indian-red);
        transform-origin: 100% 0;
        transition: transform 0.5s;
        transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
      }

      &:hover {
        &::before {
          transform-origin: 0 50%;
          transform: scale3d(1, 1, 1);
        }
      }
    }
  }
}

.display-medium {
  text-transform: uppercase;
  font-weight: 500;
}

.body-medium {
  margin-bottom: 5px;
  font-size: 13px;
  color: var(--c-text-secondary);
}

.headline-large {
  margin-top: var(--vertical-offset);
  line-height: 1.4;
  font-weight: 400;
  text-transform: none;
}

.title-large {
  font-size: 1.4em;
  line-height: 1.7;
  text-transform: uppercase;
}

.display-large {
  @include component-loaded;
  margin-top: var(--vertical-offset);
  margin-bottom: calc(var(--vertical-offset) / 3);
  margin-left: -0.04em;
  font-size: max(7.6em, 7.6vw);
  font-weight: 400;
  text-transform: none;
}
