body {
  overflow-x: clip;
  background-color: var(--c-black-amber);
}

.page {
  --stripes-c-thread: transparent;
  --stripes-c-wide: var(--c-snow-white);
  --stripes-width: calc((100% + var(--glob-gap)) - var(--aside-width));

  position: relative;
  width: 100vw;
  min-height: 100vh;
  display: grid;
  grid-template: minmax(var(--header-height), auto) 1fr auto / 1fr;
  gap: 0;
  background-color: var(--c-background-primary);

  body[class^=is-page-bin-] &,
  body.is-page-projects & {
    --stripes-width: calc(100% + var(--glob-gap));
  }

  @include min-width(touch) {
    &::after {
      @include component-loaded;
      pointer-events: none;
      content: '';
      position: fixed;
      inset: 0;
      box-shadow: 5px 5px 0 0 var(--c-black-amber) inset,
                  5px -5px 0px 0px var(--c-black-amber) inset;
      z-index: var(--z-index-black-frame);
    }

    grid-template: minmax(var(--header-height), auto) 1fr auto / 1fr 1fr var(--aside-width);

    body[class^=is-page-bin-] &,
    body.is-page-projects & {
      grid-template: minmax(var(--header-height), auto) 1fr auto / 1fr 1fr;
    }
  }
}

.header {
  position: relative;
  z-index: 2;

  @include min-width(touch) {
    grid-row: 1 / 2;
    grid-column: 1 / 3;
  }
}

.aside {
  @include min-width(touch) {
    grid-row: 1 / 4;
    grid-column: 3 / 4;
  }
}

.main {
  position: relative;
  z-index: 2;
  overflow: hidden;

  @include min-width(touch) {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
  }
}

.footer {
  position: relative;
  z-index: 2;

  @include min-width(touch) {
    grid-row: 3 / 4;
    grid-column: 1 / 3;
  }
}
